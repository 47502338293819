import React from "react";
import { useLocation, Link } from "react-router-dom";

import avatarSvg from './img/avatar.svg';
import coinSvg from './img/coin.svg';
import vector3Svg from './img/vector-3.svg';
import backButton from "./img/back-button.svg";
import infoButton from "./img/info-button.svg";

import styles from "./Header.module.css";

const Header = ({ userData, onTutorialToggle }) => {
  const location = useLocation();

  const { userName, balance, rating } = userData;
  const avatar = userData.avatarURL ? userData.avatarURL : avatarSvg;

  const showBackButton = ['/game', '/leaderboard'].includes(location.pathname);
  const showInfoButton = location.pathname !== '/leaderboard';

  return (
    <div className={styles["header"]}>
      {showBackButton ? (
        <div className={styles["left-block"]}>
          <Link to="/">
            <img className="back-button" alt="" src={backButton} />
          </Link>
          {showInfoButton && (
            <img className="info-button" onClick={onTutorialToggle} alt="" src={infoButton} />
          )}
        </div>
      ) : (
        <div className={styles["left-block"]}>
          <img className={styles["avatar"]} src={avatar} alt="avatar" />
          <div className={styles["name-rating-block"]}>
            <div className={styles["username-wrapper"]}>{userName}</div>
            <div className={styles["rating"]}>
              <div className={styles["rating-wrapper"]}>{rating}</div>
              <img className={styles["img"]} src={vector3Svg} alt="rating" />
            </div>
          </div>
        </div>
      )}
      
      <div className={styles["right-block"]}>
        <div className={styles["balance"]}>
          <div className={styles["amount"]}>
            <div className={styles["text-wrapper"]}>{balance}</div>
            <img 
              className={styles["coin-shaped-point"]} 
              alt="coin" 
              src={coinSvg} 
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
