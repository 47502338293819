import React from "react";
import styles from './Leaderboard.module.css';

import goldenCup from './svg/golden-cup.svg';
import silverCup from './svg/silver-cup.svg';
import bronzeCup from './svg/bronze-cup.svg';

const Leaderboard = ({ leaderboardData }) => {
  // Функция для отображения медалей или ранга
  const getIconOrRank = (rank) => {
    switch (rank) {
      case 1:
        return (
          <div className={styles["vector-container"]}>
            <img className={styles.vector} alt="Golden Cup" src={goldenCup} />
          </div>
        );
      case 2:
        return (
          <div className={styles["vector-container"]}>
            <img className={styles.vector} alt="Silver Cup" src={silverCup} />
          </div>
        );
      case 3:
        return (
          <div className={styles["vector-container"]}>
            <img className={styles.vector} alt="Bronze Cup" src={bronzeCup} />
          </div>
        );
      default:
        return (
          <div className={styles["vector-container"]}>
            <div className={styles.rank}>{rank}</div>
          </div>
        );
    }
  };

  // Найти текущего пользователя
  const currentUser = leaderboardData.find(player => player.current_user);

  // Проверить, входит ли текущий пользователь в топ-30
  const isCurrentUserInTop30 = leaderboardData.slice(0, 30).some(player => player.current_user);

  return (
    <div className={styles.leaderboard}>
      <div className={styles["header-board"]}>
        <div className={styles["text-wrapper-4"]}>Weekly Leaders</div>
        <div className={styles["text-wrapper-5"]}>Words solved</div>
      </div>

      <div className={styles.list}>
        {leaderboardData.slice(0, 30).map((player, index) => (
          <div className={styles.player} key={index}>
            {getIconOrRank(player.rating)}
            <div className={styles["text-wrapper"]}>{player.username}</div>
            <div className={styles["text-wrapper-2"]}>{player.level}</div>
          </div>
        ))}
      </div>

      {!isCurrentUserInTop30 && currentUser && (
        <div className={styles["player-21"]}>
          <div className={styles["text-wrapper-2"]}>{currentUser.rating}</div>
          <div className={styles["text-wrapper"]}>{currentUser.username}</div>
          <div className={styles["text-wrapper-6"]}>{currentUser.level}</div>
        </div>
      )}
    </div>
  );
};

export default Leaderboard;
