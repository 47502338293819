import PropTypes from "prop-types";
import React from "react";
import styles from "./LetterBox.module.css";

const LetterBox = ({ letterType, className, text }) => {
    return (
        <div className={`${styles['letter-box']} ${styles[letterType]} ${className}`}>
            <div className={styles['text-wrapper']}>{`${text}`}</div>
        </div>
    );
};

LetterBox.propTypes = {
    letterType: PropTypes.oneOf(["hit", "miss", "wrong", "default"]),
};

export default LetterBox;