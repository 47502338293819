import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import Main from "./Pages/Main/Main";
import NoPage from "./Pages/NoPage/NoPage";
import Level from "./Pages/Game/Level";
import Leaderboard from "./Pages/Leaderboard/Leaderboard";
import Header from "./Components/Header/Header";

import './App.css';

import { useTelegram } from "./Hooks/useTelegram";
import useWebsocket from "./Hooks/useWebsocket";
import handleWebSocketMessage from "./WebsocketHandlers";

function App() {
  const { tg, user } = useTelegram();

  const { socket, isConnected } = useWebsocket("guessit.ru", {
    reconnectInterval: 5000,
    maxRetries: 10
  });

  const [userData, setUserData] = useState({
    userId: '',
    userName: '',
    avatarURL: '',
    balance: null,
    rating: null,
    levelId: null
  });

  const [gameData, setGameData] = useState({
    attemptsData: [],
    totalAttempts: 6,
    usedLetters: [],
    revealedWord: null,
    levelVersion: null,
    levelId: null,
    winStatus: false
  });

  const [leaderboardData, setLeaderboardData] = useState([]);
  const [winStatus, setWinStatus] = useState(false);

  useEffect(() => {
    if (socket && isConnected && user && tg?.initData) {
      socket.emit("message", { type: "GET_USER_DATA", user, authData: tg.initData });
      socket.emit("message", { type: "GET_USER_GAME_DATA", user, authData: tg.initData });
      socket.emit("message", { type: "GET_LEADERBOARD_DATA", user, authData: tg.initData });
  
      socket.on("message", (data) => {
        handleWebSocketMessage(data, { 
          setUserData, 
          setGameData, 
          setLeaderboardData, 
          setWinStatus
        });
      });
    }
  
    return () => {
      if (socket) {
        socket.disconnect(); // Закрытие соединения при размонтировании компонента
      }
    };
  }, [user, tg, socket, isConnected]);

  const [isTutorialVisible, setIsTutorialVisible] = useState(false);

  const closeAwardWindow = () => {
    setWinStatus(false);
    socket.emit("message", { type: "GET_USER_GAME_DATA", user, authData: tg.initData });
  }

  return (
    <div className="appContainer">
      <div className="main">
        <Header 
          userData={userData}
          onTutorialToggle={() => setIsTutorialVisible(!isTutorialVisible)}
        />
        <Routes>
            <Route index element={<Main userData={userData} />}/>
            <Route path={'game'} element={
              <Level 
                socket={socket}
                isConnected={isConnected}
                authData={tg.initData}
                user={user}
                userData={userData}
                gameData={gameData}
                isTutorialVisible={isTutorialVisible}
                onTutorialToggle={() => setIsTutorialVisible(!isTutorialVisible)}
                isAwardVisible={winStatus}
                closeAwardWindow={closeAwardWindow}
              />
            }/>
            <Route path={'leaderboard'} element={
              <Leaderboard 
                leaderboardData={leaderboardData}
              />
            }/>
            <Route path='*' element={<NoPage />} />
        </Routes>
        </div>
    </div>
  );
}

export default App;
