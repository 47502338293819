import React from "react";
import styles from "./NoPage.module.css";

const NoPage = () => {
    return (
      <div className={styles.main}>
        <div className={styles['text-wrapper']}>Page Not Found</div>
      </div>
    );
};

export default NoPage;
