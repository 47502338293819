import PropTypes from "prop-types";
import React from "react";
import styles from "./KeyboardApply.module.css";

import KeyboardApplyInactive from "./svg/apply-inactive.svg"
import KeyboardApplyActive from "./svg/apply-active.svg"
import KeyboardApplyPress from "./svg/apply-press.svg"

const KeyboardApply = ({ buttonState, className, onClick }) => {
    return (
        <img
            className={`${styles.img} ${className}`}
            alt="Apply"
            src={
                buttonState === "active"
                ? KeyboardApplyActive
                : buttonState === "press"
                ? KeyboardApplyPress
                : KeyboardApplyInactive
            }
            onClick={onClick}
        />
    );
};

KeyboardApply.propTypes = {
    buttonState: PropTypes.oneOf(["press", "inactive", "active"]),
};

export default KeyboardApply;
