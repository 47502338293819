import React from "react";

import LetterBox from "../LetterBox/LetterBox";
import PlayButtonBig from "../PlayButtonBig/PlayButtonBig";

import perfectIcon from "./img/perfect-ps.png";
import vector9Svg from "./img/vector-9.svg";
import line2Svg from "./img/line-2.svg";
import vector12Svg from "./img/vector-12.svg";

import styles from "./Award.module.css";

const Award = ({ userData={}, attempts=["PRIZE"], closeAwardWindow=()=>{} }) => {
    // Получаем последнюю непустую попытку
    const letters = attempts.reverse().find(attempt => attempt !== "")?.split("");

    return (
        <div className={styles.main}>
            <div className={styles["congrats"]}>
              <div className={styles["perfect-PS-wrapper"]}>
                <img className={styles["perfect-PS"]} alt="" src={perfectIcon} />
              </div>
            </div>

            <div className={styles["progress"]}>
              <div className={styles.elementTry}>
                {letters.map((letter, index) => (
                  <LetterBox
                    key={index}
                    className={styles.letterBox}
                    letterType="hit"
                    text={letter}
                  />
                ))}
              </div>
            </div>

            <div className={styles["rewards"]}>
              <div className={styles["frame"]}>
                <img
                  className={styles["vector-2"]}
                  alt=""
                  src={vector9Svg}
                />

                <div className={styles["text-wrapper-9"]}>{userData.balance}</div>
              </div>

              <img className={styles["line-2"]} alt="" src={line2Svg} />

              <div className={styles["frame"]}>
                <div className={styles["coin-shaped-point-2"]}>
                  <div className={styles["group"]}>
                    <div className={styles["vector-wrapper"]}>
                      <img
                        className={styles["vector"]}
                        alt="Vector"
                        src={vector12Svg}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles["text-wrapper-10"]}>225</div>
              </div>
            </div>

            <PlayButtonBig onClick={closeAwardWindow} text="NEXT" />
        </div>
    );
};

export default Award;
