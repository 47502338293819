import React, { useState, useEffect, useMemo } from "react";

import Tutorial from "../../Components/Tutorial/Tutorial";
import Award from '../../Components/Award/Award'
import KeyboardApply from "../../Components/Keyboard/KeyboardApply";
import KeyboardErase from "../../Components/Keyboard/KeyboardErase";
import KeyboardLetter from "../../Components/Keyboard/KeyboardLetter";
import LetterBox from "../../Components/LetterBox/LetterBox";
import Hint from "../../Components/Hint/Hint"

import styles from "./Level.module.css";

const checkWord = async (word) => {
  try {
    const response = await fetch('/api/check-word', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ word }),
    });

    if (!response.ok) {
      throw new Error('Failed to check word.');
    }
    const data = await response.json();
    return data.exists;
  } catch (error) {
    console.error('Error checking word:', error);
    return false;
  }
};

const calculateAttempts = (attemptsData, totalAttempts) => {
  const maxVisibleRows = 6;
  let updatedAttempts = attemptsData.map((attempt) => attempt.word);
  updatedAttempts.push("");
  updatedAttempts = updatedAttempts.slice(
    Math.max(0, totalAttempts - maxVisibleRows),
    totalAttempts
  );
  while (updatedAttempts.length < maxVisibleRows) {
    updatedAttempts.push("");
  }
  return updatedAttempts;
};

export const Level = ({ 
  user, authData, gameData, userData,
  socket, isConnected,
  isTutorialVisible, onTutorialToggle,
  isAwardVisible, closeAwardWindow
}) => {
  // Максимальное количество попыток
  const { attemptsData } = gameData;

  // Начальное состояние массива с попытками с учетом пустых строк в конце
  const [attempts, setAttempts] = useState(() =>
    calculateAttempts(gameData.attemptsData, gameData.totalAttempts)
  );
  
  useEffect(() => {
    setAttempts(calculateAttempts(gameData.attemptsData, gameData.totalAttempts));
  }, [gameData]);

  // Номер текущей попытки
  const currentAttemptIndex = gameData.attemptsData.length;

  // Состояние кнопок "стереть" и "применить"
  const [isApplyActive, setIsApplyActive] = useState(false);
  const [isEraseActive, setIsEraseActive] = useState(false);

  // Индикатор корректности введенного слова
  const [isWordValid, setIsWordValid] = useState(false);

  useEffect(() => {
    setIsApplyActive(false);
    setIsEraseActive(false);
    setIsWordValid(false);
  }, [gameData]);

  // Преобразуем usedLetters в объект { letter: flag }
  const letterFlags = useMemo(() => {
    return gameData.usedLetters.reduce((acc, { letter, flag }) => {
      acc[letter] = flag;
      return acc;
    }, {});
  }, [gameData.usedLetters]);

  const handleLetterClick = async (letter) => {
    const currentAttempt = attempts[currentAttemptIndex];
    if (currentAttempt.length < 5) {
      const newAttempt = currentAttempt + letter.toUpperCase();
      const newAttempts = [...attempts];
      newAttempts[currentAttemptIndex] = newAttempt;
      setAttempts(newAttempts);

      if (newAttempt.length === 5) {
        const _isWordValid = await checkWord(newAttempt);
        if (_isWordValid) {
          setIsWordValid(true);
          setIsApplyActive(true);
        }
      }
      setIsEraseActive(newAttempt.length > 0);
    }
  };

  const handleErase = () => {
    const currentAttempt = attempts[currentAttemptIndex];
    if (currentAttempt.length > 0) {
      const newAttempt = currentAttempt.slice(0, -1);
      const newAttempts = [...attempts];
      newAttempts[currentAttemptIndex] = newAttempt;
      setAttempts(newAttempts);

      setIsEraseActive(newAttempt.length > 0);
      setIsApplyActive(false);
      setIsWordValid(false);
    }
  };

  const handleApply = async () => {
    const currentAttempt = attempts[currentAttemptIndex];
    if (currentAttempt.length === 5) {
      if (isWordValid) {
        if (socket && isConnected) {
          socket.emit("message", { 
            type: "UPDATE_USER_GAME_DATA",
            user, 
            authData,
            attemptWord: currentAttempt
          });
        } else {
          handleErase();
        }
      }
    }
  };

  const renderLetterBoxes = () => {
    return attempts.map((attempt, rowIndex) => {
      const attemptData = attemptsData[rowIndex] || {}; // Получаем данные для попытки, если они есть
      return (
        <div key={`attempt-${rowIndex}`} className={styles.elementTry}>
          {[...Array(5)].map((_, letterIndex) => {
            const letter = attempt[letterIndex] || "";
            const letterData = attemptData.letters?.[letterIndex] || {}; // Данные о букве
            const flag = letterData.flag;

            let letterType = "default"; // По умолчанию "default"
            if (flag === 1) {
              letterType = "hit"; // Если flag = 1, то это правильная буква
            } else if (flag === -1) {
              letterType = "wrong"; // Если flag = -1, то буква неправильная
            } else if (flag === 0) {
              letterType = "miss"; // Если flag = 0, то буква не в слове
            }

            return (
              <LetterBox
                key={`letter-box-${rowIndex}-${letterIndex}`}
                className={styles.letterBox}
                letterType={letterType} // Устанавливаем свойство для LetterBox
                text={letter.toUpperCase()} // Отображаем букву в верхнем регистре
              />
            );
          })}
        </div>
      );
    });
  };

  const renderKeyboard = () => {
    const keyboardRows = [
      "QWERTYUIOP".split(""),
      "ASDFGHJKL".split(""),
      "ZXCVBNM".split(""),
    ];

    return (
      <div className={styles.keyboard}>
        {keyboardRows.map((row, rowIndex) => (
          <div key={`keyboard-row-${rowIndex}`} className={styles.keyboardRow}>
            {rowIndex === 2 && (
              <KeyboardApply
                className={styles.keyboardApply}
                buttonState={isApplyActive ? "active" : "inactive"}
                onClick={handleApply}
              />
            )}
            {row.map((letter) => {
              // Получаем флаг для буквы
              const flag = letterFlags[letter] ?? null;
              let letterStyle = "default";

              // Определяем property1 на основе флага
              if (flag === 1) letterStyle = "hit";
              else if (flag === 0) letterStyle = "miss";
              else if (flag === -1) letterStyle = "wrong";

              return (
                <KeyboardLetter
                  key={`keyboard-letter-${letter}`}
                  className={styles.keyboardLetter}
                  letterStyle={letterStyle}
                  letter={letter}
                  onClick={() => handleLetterClick(letter)}
                />
              );
            })}
            {rowIndex === 2 && (
              <KeyboardErase
                className={styles.keyboardErase}
                buttonState={isEraseActive ? "active" : "inactive"}
                onClick={handleErase}
              />
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.level}>
      <div>
        <div className={styles.lettersBox}>
          {renderLetterBoxes()}
        </div>
        <div className={styles.hintsBox}>
          <Hint 
            type='clue' 
            user={user}
            authData={authData}
            socket={socket}
            isConnected={isConnected}
          />
          <Hint 
            type='broom' 
            user={user}
            authData={authData}
            socket={socket}
            isConnected={isConnected}
          />
        </div>
        {renderKeyboard()}
      </div>
      {isTutorialVisible && (
        <div className={styles.tutorBox}>
          <Tutorial onTutorialToggle={onTutorialToggle} />
        </div>
      )}
      {isAwardVisible && (
        <div className={styles.awardBox}>
          <Award 
            userData={userData} 
            attempts={attempts}
            closeAwardWindow={closeAwardWindow}
          />
        </div>
      )}
    </div>
  );
};

export default Level;
