import PropTypes from "prop-types";
import React from "react";
import styles from "./KeyboardErase.module.css";

import KeyboardEraseInactive from "./svg/erase-inactive.svg"
import KeyboardEraseActive from "./svg/erase-active.svg"

const KeyboardErase = ({ buttonState, className, onClick }) => {
    return (
        <img
            className={`${styles.img} ${className}`}
            alt="Erase"
            src={
                buttonState === "active" 
                ? KeyboardEraseActive 
                : KeyboardEraseInactive
            }
            onClick={onClick}
        />
    );
};

KeyboardErase.propTypes = {
    buttonState: PropTypes.oneOf(["active", "inactive"]),
};

export default KeyboardErase;
