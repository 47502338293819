import React from "react";
import LetterBox from "../LetterBox/LetterBox";
import PlayButtonBig from "../PlayButtonBig/PlayButtonBig";
import styles from "./Tutorial.module.css";

const Tutorial = ({ onTutorialToggle }) => {
    return (
        <div className={styles.main}>
            <div className={styles.overlap}>
                <div className={styles['text-wrapper-2']}>Tutorial</div>
            </div>
            <div className={styles.p}>
                You have 5 attempts to solve the puzzle. After each guess, the colors of the letters will give you hints.
            </div>
            <div className={styles['element-try']}>
                <LetterBox className={styles['letter-box-1']} letterType="miss" text="P" />
                <LetterBox className={styles['letter-box-2']} letterType="miss" text="A" />
                <LetterBox className={styles['letter-box-3']} letterType="miss" text="P" />
                <LetterBox className={styles['letter-box-4']} letterType="wrong" text="E" />
                <LetterBox className={styles['letter-box-5']} letterType="wrong" text="R" />
            </div>
            <p className={styles['the-letters-p-z-and']}>
                Orange letters like "P" and "A" are present in the hidden word but in the wrong places. 
                Gray letters like "E" and "R" are not present in the hidden word.
            </p>
            <div className={styles['element-try']}>
                <LetterBox className={styles['letter-box-1']} letterType="hit" text="S" />
                <LetterBox className={styles['letter-box-2']} letterType="hit" text="P" />
                <LetterBox className={styles['letter-box-3']} letterType="hit" text="A" />
                <LetterBox className={styles['letter-box-4']} letterType="wrong" text="R" />
                <LetterBox className={styles['letter-box-5']} letterType="wrong" text="K" />
            </div>
            <p className={styles['the-letters-s-p-and']}>
                Green letters like "S", "P" and "A" are in the correct spots.
            </p>
            <div className={styles['element-try']}>
                <LetterBox className={styles['letter-box-1']} letterType="hit" text="S" />
                <LetterBox className={styles['letter-box-2']} letterType="hit" text="P" />
                <LetterBox className={styles['letter-box-3']} letterType="hit" text="A" />
                <LetterBox className={styles['letter-box-4']} letterType="hit" text="W" />
                <LetterBox className={styles['letter-box-5']} letterType="hit" text="N" />
            </div>
            <p className={styles['text-wrapper-4']}>
                The hidden word may have repeated letters, such as "P".
                All your guesses must be real words. When you guess correctly, all letters will turn green.
            </p>
            <PlayButtonBig text="GOT IT!" onClick={onTutorialToggle} />
        </div>
    );
};

export default Tutorial;
