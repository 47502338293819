import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from './Hint.module.css';

import clueIcon from "./img/clue.svg";
import broomIcon from "./img/broom.png";
import coinIcon from './img/coin.svg'

const Hint = ({ type, user, authData, socket, isConnected}) => {
  let sideClass;
  let hintPrice;
  let hintIcon;

  if (type === 'clue') {
    hintPrice = 350;
    hintIcon = clueIcon;
    sideClass = 'left-side';
  } else if (type === 'broom') {
    hintPrice = 250;
    hintIcon = broomIcon;
    sideClass = 'right-side';
  } else {
    return (<div></div>);
  }

  return (
    <div className={`${styles['hint']} ${styles[sideClass]}`}>
      <div className={styles["button-gift"]}>
        <div className={styles["overlap"]}>
          <div className={styles["group"]}>
            <img alt="" className={styles['hint-icon']} src={hintIcon} />
          </div>
          <div className={styles["text-wrapper"]}>
            <img alt="" className={styles['hint-icon']} src={coinIcon} />
            {hintPrice}
          </div>
        </div>
      </div>
    </div>
  );
};

Hint.propTypes = {
  type: PropTypes.oneOf(["clue", "broom"]),
};

export default Hint;
