import React from "react";
import { Link } from "react-router-dom";

import infinitySvg from "./img/infinity.svg";
import giftSvg from "./img/gift-svg.svg";

import PlayButtonBig from "../../Components/PlayButtonBig/PlayButtonBig";
import styles from "./Main.module.css";

const Main = ({ userData }) => {
  const { levelId } = userData;

  return (
    <div className={styles.landing}>
        <div class={styles["gift-block"]}>
          <div className={styles["button-gift"]}>
            <div className={styles["overlap-2"]}>
              <div className={styles["group-2"]}>
                <img alt="" src={giftSvg} />
              </div>
              <div className={styles["text-wrapper-5"]}>FREE GIFT</div>
            </div>
          </div>
          <Link to="/leaderboard">
            <div className={styles["ladder"]}>
              <div className={styles["overlap-2"]}>
                <div className={styles["group-2"]}>
                  <div className={styles["leaderboard"]}>
                    <div className={styles["rectangle"]} />
                    <div className={styles["rectangle-2"]} />
                    <div className={styles["rectangle-3"]} />
                  </div>
                </div>
                <div className={styles["text-wrapper-5"]}>LEADERS</div>
              </div>
            </div>
          </Link>
        </div>
        
        <div className={styles["text-wrapper-7"]}>Wordle</div>

        <div className={styles["progress"]}>
          <div className={styles["unlimited"]}>
            <img className={styles["infinity"]} alt="" src={infinitySvg} />
            <div className={styles["text-wrapper-8"]}>Unlimited</div>
          </div>
          <div className={styles["text-wrapper-9"]}>Level {levelId}</div>
          <Link to="/game" style={{ textDecoration: 'none' }}>
            <PlayButtonBig
              className={styles["play-button-instance"]}
              property1="bet"
            />
          </Link>
        </div>
    </div>
  );
};

export default Main;
