function handleWebSocketMessage(data, setters) {
  if (data.type === 'GET_USER_DATA_RESPONSE') {
    setters.setUserData({
      userId: data.user.id,
      userName: data.user.username,
      avatarURL: data.user.avatarURL,
      balance: data.user.balance,
      rating: data.user.rating,
      levelId: data.user.level_id,
      isPremium: data.user.is_premium,
      language: data.user.language
    });
  } else if (data.type === 'GET_GAME_DATA_RESPONSE') {
    setters.setGameData({
      attemptsData: data.gameData.attemptsData,
      totalAttempts: data.gameData.totalAttempts,
      usedLetters: data.gameData.usedLetters,
      revealedWord: data.gameData.revealedWord,
      levelVersion: data.gameData.levelVersion,
      levelId: data.gameData.revealedWord,
      winStatus: data.winStatus || false
    });
  } else if (data.type === 'GET_LEADERBOARD_DATA_RESPONSE') {
    setters.setLeaderboardData(data.leadersData);
  } else if (data.type === 'WIN_RESPONSE') {
    setters.setWinStatus(data.winStatus);
  } else if (data.type === 'ERROR') {
    console.error('Error:', data.message);
  }
}

export default handleWebSocketMessage;
