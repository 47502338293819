import { React } from "react";
import styles from "./PlayButtonBig.module.css";

const PlayButtonBig = ({ text="PLAY", onClick=()=>{} }) => {
    return (
        <button className={`${styles['play-button']} ${styles.bet}`} onClick={onClick}>
            <div className={styles['text-wrapper']}>{text}</div>
        </button>
    );
};

export default PlayButtonBig;
