import { useEffect, useState } from "react";
import io from "socket.io-client";

const useWebsocket = (url, options = {}) => {
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  const protocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
  const finalUrl = `${protocol}${url}`;

  const {
    reconnectInterval = 5000, // интервал между попытками переподключения
    maxRetries = 10, // максимальное количество попыток переподключения
  } = options;

  useEffect(() => {
    // Создаем подключение с настройками переподключения
    const socketInstance = io(finalUrl, {
      transports: ['websocket'], // используем WebSocket как транспорт
      reconnection: true, // включаем автоматическое переподключение
      reconnectionAttempts: maxRetries, // максимальное количество попыток переподключения
      reconnectionDelay: reconnectInterval, // интервал между попытками
    });

    // Устанавливаем обработчики событий
    socketInstance.on('connect', () => {
      setIsConnected(true);
      setSocket(socketInstance);
      console.log("Socket.IO connection established");
    });

    socketInstance.on('disconnect', () => {
      setIsConnected(false);
      console.log("Socket.IO connection closed");
    });

    socketInstance.on('connect_error', (error) => {
      console.error("Socket.IO error:", error);
    });

    socketInstance.on('connect_timeout', () => {
      console.log("Socket.IO connection timeout");
    });

    return () => {
      // Закрываем соединение при размонтировании компонента
      socketInstance.disconnect();
    };
  }, [finalUrl, reconnectInterval, maxRetries]);

  return { socket, isConnected };
};

export default useWebsocket;
