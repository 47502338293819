import PropTypes from "prop-types";
import React from "react";
import styles from "./KeyboardLetter.module.css";

const KeyboardLetter = ({ className, letterStyle, letter, onClick }) => {
    return (
        <div 
            className={`${styles['keyboard-letter']} ${styles[letterStyle]} ${className}`}
            onClick={onClick}
        >
            <div className={styles['text-wrapper']}>{letter}</div>
        </div>
    );
};

KeyboardLetter.propTypes = {
    letterStyle: PropTypes.oneOf(["hit", "default", "wrong", "press", "miss"]),
};

export default KeyboardLetter;